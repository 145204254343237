<template>
  <div>
    <vs-table stripe max-items="10" pagination :data="spocs">
      <template slot="header">
        <div style="display: flex">
          <h3 style="margin-bottom: 10px">
            Eligibility Spoc Summary - {{ eligibilitySpocTableProp.city }}
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Name </vs-th>
        <vs-th> Reporting Manager </vs-th>
        <vs-th> City </vs-th>
        <vs-th> Overall Eligibility Enrollments </vs-th>
        <vs-th> Eligibility Checks Among Enrolled </vs-th>
        <vs-th> New Eligibility Checks </vs-th>
        <vs-th> New Eligibility Checks Enrolled </vs-th>
        <vs-th> New Eligibility Conversion </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>

          <vs-td :data="data[indextr].reporting_manager">
            {{ data[indextr].reporting_manager }}
          </vs-td>

          <vs-td :data="data[indextr].city">
            {{ data[indextr].city }}
          </vs-td>

          <vs-td :data="data[indextr].overall_eligibility_enrollments">
            {{ data[indextr].overall_eligibility_enrollments }}
          </vs-td>
          <vs-td :data="data[indextr].eligibility_checks_among_enrolled">
            {{ data[indextr].eligibility_checks_among_enrolled }}
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_checks">
            {{ data[indextr].new_eligibility_checks }}
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_checks_enrolled">
            {{ data[indextr].new_eligibility_checks_enrolled }}
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_conversion">
            {{ data[indextr].new_eligibility_conversion }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ["eligibilitySpocTableProp", "spocs"],
};
</script>