<template >
  <div>
    <!-- <div v-if="(referrals_city_wises.length < 1)">No Data Available</div> -->
    <div v-if="referrals_city_wises.length > 0">
      <!-- <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white;">
                    {{ referrals_city_wise.city }}
                </h6>
            </div>
            <div v-on:click="getReferralsSpocSummary(referrals_city_wise.city)" class="count">{{ referrals_city_wise.overall_referral_enrollments }} </div>
        </vs-card> -->
      <vs-table max-items="5" pagination stripe :data="referrals_city_wises">
        <template slot="thead">
          <vs-th> City </vs-th>
          <vs-th> Overall Referral Enrollments </vs-th>
          <vs-th> Referral Among Enrolled %</vs-th>
          <vs-th> New Referral Count </vs-th>
          <vs-th> New Referral Enrolled Count </vs-th>
          <vs-th> New Referrals Conversion %</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].city">
              {{ data[indextr].city }}
            </vs-td>

            <vs-td :data="data[indextr].overall_referral_enrollments">
              <div
                style="color: #948bf4"
                @click="getReferralsSpocSummary(data[indextr].city)"
              >
                {{ data[indextr].overall_referral_enrollments }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].referral_among_enrolled">
              <div>{{ data[indextr].referral_among_enrolled }}</div>
            </vs-td>
            <vs-td :data="data[indextr].new_referral_count">
              <div>
                {{ data[indextr].new_referral_count }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].new_referral_enrolled_count">
              <div>
                {{ data[indextr].new_referral_enrolled_count }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].new_referrals_conversion">
              <div>
                {{ data[indextr].new_referrals_conversion }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["referrals_city_wises"],
  mounted() {},
  methods: {
    getReferralsSpocSummary: function (city) {
      this.$emit("show-referrals-spoc-summary", city);
    },
  },
};
</script>
<style scoped>
.count {
  color: black;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  background-color: white;
  margin: -8%;
}
</style>