<template>
    <div>
        <vs-popup fullscreen  :title="`Roadmap City Wise | ${date_props.startDate}- ${date_props.endDate}`" :active.sync="open_popup" @close="closePopup">
            <roadmap-city-wise-table :roadmap_city_wises="roadmap_city_wises"
                @show-roadmap-spoc-summary="getRoadmapSpocSummary"></roadmap-city-wise-table>
            <roadmap-spoc-summary :spocs="spocs" :city="city" v-if="spocs.length > 0"></roadmap-spoc-summary>
        </vs-popup>
    </div>
</template>
<script>
import ApiServices from "../../ApiServices";
import RoadmapCityWiseTable from "../../components/CmDashboard/RoadmapCityWiseTable.vue";
import RoadmapSpocSummary from "../../components/CmDashboard/RoadmapSpocSummary.vue"
export default {
    props: ["roadmap_city_summary_popup", "roadmap_city_wises", "date_props"],
    components: {
        RoadmapCityWiseTable,
        RoadmapSpocSummary
    },
    data() {
        return {
            open_popup: false,
            course: "",
            spocs: [],
            city: "",
        }
    },
    watch: {
        roadmap_city_summary_popup(val) {
            this.open_popup = val
        }
    },
    methods: {
        closePopup() {
            this.$emit("close-roadmap-city-popup");
            this.spocs = [];
        },
        getRoadmapSpocSummary(city) {
            this.spocs = [];
            this.city = city
            console.log("GetRoadMap", city, this.date_props)
            ApiServices.getRoadmapSpocSummary(this.date_props, city)
                .then((response) => {
                    console.log("RoadSummary", response.data)
                    this.spocs = response.data
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });

        }
    }
}
</script>