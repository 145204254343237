<template>
  <div>
    <vs-popup fullscreen :title="`Enrollment City Summary | ${this.date_props.card_header}`"
      :active.sync="enrollments_popup" @close="closePopup">
      <vs-row vs-justify="center" vs-type="flex" vs-w="12">
        <vs-col vs-offset="0" vs-type="flex" vs-w="2" v-for="(city_wises_enrollment, index) in city_wises_enrollments"
          :key="index">
          <city-wise-card :city_wises_enrollment="city_wises_enrollment" @show-city-enrollment="getSpocSummary">
          </city-wise-card>
        </vs-col>
      </vs-row>
      <spoc-summary :spocs="spocs" :city="city" v-if="spocs.length > 0"></spoc-summary>
    </vs-popup>
  </div>
</template>

<script>
import CityWiseCard from "../../components/CmDashboard/CityWiseCard.vue";
import SpocSummary from "../../components/CmDashboard/SpocSummary.vue";
import ApiServices from "../../ApiServices";


export default {
  props: ["enrollments_popup", "city_wises_enrollments", "date_props","team"],
  components: {
    CityWiseCard,
    SpocSummary
  },
  data() {
    return {
      course: "",
      spocs: [],
      city: []
    }
  },
  methods: {
    getSpocSummary(city) {
      console.log("date", this.date_props);
      this.city = { "city": city }
      this.$vs.loading();
      if (this.date_props.course != null && this.date_props.course != '') {
        this.course = this.date_props.course
      }
      let enrollmentSpoc = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
        course: this.course,
        spoc: this.date_props.spoc,
        gm: this.date_props.gm,
        team: this.team
      };
      // let url = `${constants.MILES_DASHBOARDS}getSpocSummary?start_date=${this.start_date}&end_date=${this.end_date}&city=${this.summary_details.city}&course=${this.summary_details.course}&spoc=${this.summary_details.spoc}&gm=${this.summary_details.gm}`;
      // // let url = `http://milesapi.bulbot.com/api/performance-dashboard/enrollments-spoc-summary/?city=${this.summary_details.city}&end-date=${this.end_date}&start-date=${this.start_date}&course=${this.summary_details.course}`;

      // axios
      //     .get(url, {
      //         headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //     })
      ApiServices.getSpocSummary(enrollmentSpoc)
        .then((response) => {
          // console.log("spoc Summary", response.data)
          this.spocs = response.data
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    closePopup() {
      this.course = ""
      this.$emit("close-enrollments-popup")
      this.spocs = []
    }
  }
}
</script>