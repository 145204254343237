<template>
  <div>
    <vs-popup
      class="popupwidth"
      :id="'utilization_untracked'"
      :title="this.iimlname + ' - ' + this.iimltab + ' - ' + this.getData.length"
      :active.sync="open"
      v-if="open"
    >
      <div>
        <vs-table :data="calls[0]">
          <template slot="thead" class="exc">
            <vs-th>Number</vs-th>
            <vs-th>Count</vs-th>
            <vs-th>Type</vs-th>
            <!-- <vs-th v-if="iimltab=='IVR All Calls'">Duration</vs-th> -->
            <vs-th>Date & Times</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="item.data.phone_number" v-for="item in data">
              <vs-td>
                <div>{{ item.data.phone_number }}</div>
              </vs-td>
              <vs-td>
                <div>{{ item.count }}</div>
              </vs-td>
              <vs-td>
                <div>{{ item.data.contact_type }}</div>
              </vs-td>
              <!-- <vs-td v-if="iimltab=='IVR All Calls'">
                <div>{{ secondsToHms(item.data.call_duration) }}</div>
              </vs-td> -->
              <vs-td>
                <div>{{ unixTimestampConvert(item.data.start_stamp) }}</div>
              </vs-td>
              <template class="expand-user" slot="expand">
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="12"
                  >
                    <div style="width: 100%">
                      <vs-table :data="item.childdata">
                        <template slot="thead">
                          <vs-th>Number</vs-th>
                          <vs-th>Type</vs-th>
                          <!-- <vs-th v-if="iimltab=='IVR All Calls'">Duration</vs-th> -->
                          <vs-th>Date & Times</vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                              <div>{{ tr.phone_number }}</div>
                            </vs-td>
                            <vs-td>
                              <div>{{ tr.contact_type }}</div>
                            </vs-td>
                            <!-- <vs-td v-if="iimltab=='IVR All Calls'">
                              <div>{{ secondsToHms(tr.call_duration) }}</div>
                            </vs-td> -->
                            <vs-td>
                              <div>
                                {{ unixTimestampConvert(tr.start_stamp) }}
                              </div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import moment from "moment";
export default {
  data() {
    return {
      open: false,
      calls: [],
      newcalls: [],
      title: "",
      iimlname: "",
      iimltab: "",
      count: "",
      MissedTodayCallLogsdata: "",
      getData: "",
    };
  },
  mounted() {
    document.getElementById("utilization_untracked").children[1].style.width =
      "90%";
    EventBus.$on(
      "spocDashboardAdmin-IVRcalls",
      (payload, obj, category, spocName, url) => {
        console.log("payload",payload, obj, category, spocName, url);
        // console.log("url",url);
        // console.log("obj",spocName);
        // console.log("obj1",obj);
        this.getData = payload;
        this.iimlname = url;
        if (spocName === "ivr_all_calls") {
          this.iimltab = "IVR All Calls";
          this.getCountdata(payload);
        } else if (spocName === "ivr_missed_calls") {
          this.iimltab = "IVR Missed Calls";
          this.getCountdata(payload);
        }

        // payload.forEach(log => {

        //   this.calls.push("br");
        //   // this.calls.push(log);
        //   console.log(log)

        // });
        this.open = true;
      }
    );
    // document.getElementById("utilization_untracked").children[1].style.width ="70%";
  },
  methods: {
    getCountdata(payload) {
      console.log("getCountdata",payload);
      this.calls = [];
      let MissedCallLogsdata = payload;
      this.MissedTodayCallLogsdata = this.groupBy(
        MissedCallLogsdata,
        (itc) => itc.phone_number
      );
      let todayfilterdData = [];

      for (var todayCallerIdNumber of Object.keys(
        this.MissedTodayCallLogsdata
      )) {
        todayfilterdData.push({
          number: todayCallerIdNumber,
          data: this.MissedTodayCallLogsdata[todayCallerIdNumber].reduceRight(
            (a, b) => (a.phone_number > b.phone_number ? a : b)
          ),
          count: this.MissedTodayCallLogsdata[todayCallerIdNumber].length,
          childdata: this.MissedTodayCallLogsdata[todayCallerIdNumber],
        });
      }
      // this.calls = todayfilterdData;
      this.calls.push(todayfilterdData);
      console.log("this.filterdIvrAllMissedCallsdata", this.calls);
    },
    groupBy(xs, f) {
      console.log("groupBy",xs, f);
      return xs.reduce(
        (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
        {}
      );
    },
    calculatecount() {
      var unique = this.calls
        .map((name) => {
          return {
            count: 1,
            name: name.phone_number,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let clonearr = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (unique[key] === 1) {
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                clonearr.push(call);
                clonearr.push("br");
              }
            });
          } else {
            let tempArr = [];
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                tempArr.push(call);
              }
            });
            tempArr.forEach((temp, index) => {
              if (index > 0) {
                temp.count = "";
                tempArr[0].call_duration =
                  tempArr[0].call_duration + temp.call_duration;
                temp.call_duration = "";
                temp.level = "";
              } else {
                temp.count = unique[key];
              }
              clonearr.push(temp);
            });
            clonearr.push("br");
          }
        }
      }
      this.newcalls = clonearr;
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      var date = new Date(value * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return `${dateString} - ${formattedTime}`;
      }
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var formatteddate =
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2);

      return `${formatteddate}`;
    },
  },
};
</script>

<style>
/* .popupwidth .vs-popup {
    min-width: 70%;
} */
</style>
