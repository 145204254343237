<template >
  <div>
    <vs-card class="card-shadow">
      <p
        :class="getColor(visits_key)"
        style="display: flex;align-items: center;height: 43px;justify-content: center;">
        {{ getTableHeader(visits_header[visits_key]) }}
      </p>
      <div v-if="visits_value.count == null || visits_value.count == 0" class="not-found">NO Data Found</div>
      <div v-else v-on:click="getCallsCitySummary(visits_key)" class="count">
        <div
          v-if="visits_value.count != null"
          style="display: flex; justify-content: center; align-content: center"
        >
          {{ visits_value.count }}
        </div>
        <div class="present_count" v-if="visits_value.count != null">
          [{{ visits_value.utilization }}%]
        </div>
        <div v-if="visits_value.count == null">0</div>
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  props: ["visits_key", "visits_value"],
  data() {
    return {
      visits_header: {
        connected_calls: "Connected Calls",
        call_utilization: "Call Utilization",
        visit_utilization: "Visit Utilization",
        visit_count: "Visit Count",
      },
    };
  },

  methods: {
    getCallsCitySummary: function (val) {
        this.$emit("show-calls-city-summary", val);
    },
    getColor(val) {
      if (val == "connected_calls") return "connected_calls_color";
      else if (val == "visit_count") return "visit_count_color";
    },
    getTableHeader(val) {
      if (val == "Visit Count") {
        return "Visitors";
      } else {
        return val;
      }
    },
  },
};
</script>
<style scoped>
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px auto;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    color: #bebcbc;
}
.count {
  margin-top: 4%;
  color: black;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  font-weight: 600;
}
.connected_calls_color {
  background: #00e396;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.visit_count_color {
  background: #feb019;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.present_count {
  display: flex;
  margin-left: 7px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 3px;
  color: #000000;
}
.card-shadow {
  width: 160px;
  height: 120px;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>    