<template>
  <div>
    <!-- <div v-if="calls_city_wises.length < 1">No Data Available</div> -->
    <div v-if="calls_city_wises.length> 0">
    <vs-popup
      fullscreen
      :title="`Connected Calls Summary | ${this.date_props.startDate} - ${this.date_props.endDate}`"
      :active.sync="calls_city_summary_popup"
      @close="closePopup"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-offset="0"
          vs-type="flex"
          vs-w="2"
          v-for="(calls_city_wise, index) in calls_city_wises"
          :key="index"
        >
          <ConectedCalls
            :calls_city_wise="calls_city_wise"
            @show-calls-spoc-summary="getCallsSpocSummary"
          ></ConectedCalls>
        </vs-col>
      </vs-row>
      <Calls-Spoc-Summary
        :spocs="spocs"
        :callSpocTableProp="callSpocTableProp"
        v-if="spocs.length > 0"
      ></Calls-Spoc-Summary>
    </vs-popup>
  </div>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import CallsSpocSummary from "./CallsSpocSummary.vue";
import ConectedCalls from "./ConectedCalls.vue";

export default {
  props: ["calls_city_summary_popup", "calls_city_wises","date_props","team"],
  components: {
    ConectedCalls,
    CallsSpocSummary,
  },
  data() {
    return {
      callSpocTableProp: [],
      course: "",
      spocs: [],
    };
  },
  methods: {
    getCallsSpocSummary(city) {
      this.callSpocTableProp= {"startDate":this.date_props.startDate,"endDate":this.date_props.endDate,"city":city}
      this.$vs.loading();
      let callsSpoc = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
        course: this.course,
        spoc: this.date_props.spoc,
        gm: this.date_props.gm,
        team:this.team
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/calls-spoc-summary/?city=${this.summary_details.city}&end-date=${this.end_date}&start-date=${this.start_date}`;
      // let url = `${constants.MILES_DASHBOARDS}getCallsSpocSummary?start_date=${this.start_date}&end_date=${this.end_date}&city=${this.summary_details.city}&spoc=${this.summary_details.spoc}&gm=${this.summary_details.gm}`;

      // axios
      //     .get(url, {
      //         headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //     })
      ApiServices.getCallsSpocSummary(callsSpoc)
        .then((response) => {
          console.log("spoc Summary", response.data);
          this.spocs = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    closePopup(){
       this.$emit("close-connected-call-popup")
       this.spocs = []
    }
  },
};
</script>