<template >
    <div>
        <!-- <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white;">
                    {{ eligibility_city_wise.city }}
                </h6>
            </div>
            <div v-on:click="getEligibilitySpocSummary(eligibility_city_wise.city)" class="count">{{ eligibility_city_wise.overall_eligibility_enrollments }} </div>
        </vs-card> -->
    <vs-table max-items="5" pagination stripe :data="eligibility_city_wises">
      <template slot="thead">
        <vs-th>
          City
        </vs-th>
        <vs-th>
          Overall Eligibility Enrollments
        </vs-th>
        <vs-th>
          Eligibility Checks Among Enrolled %
        </vs-th>
        <vs-th>
          New Eligibility Checks
        </vs-th>
        <vs-th>
          New Eligibility Checks Enrolled
        </vs-th>
        <vs-th>
          New Eligibility Conversion %
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].city">
            {{ data[indextr].city }}
          </vs-td>

          <vs-td :data="data[indextr].overall_eligibility_enrollments">
            <div style="color:#948bf4;" @click="getEligibilitySpocSummary(data[indextr].city)"> {{ data[indextr].overall_eligibility_enrollments
            }}
            </div>
          </vs-td>
          <vs-td :data="data[indextr].eligibility_checks_among_enrolled">
            <div> {{ data[indextr].eligibility_checks_among_enrolled
            }}
            </div>
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_checks">
            <div >
              {{ data[indextr].new_eligibility_checks }} </div>
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_checks_enrolled">
            <div >
              {{ data[indextr].new_eligibility_checks_enrolled }} </div>
          </vs-td>
          <vs-td :data="data[indextr].new_eligibility_conversion">
            <div>
              {{ data[indextr].new_eligibility_conversion }} </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
</template>
<script>
export default {
    props: ["eligibility_city_wises"],
    mounted() {
    },
    methods: {
        getEligibilitySpocSummary: function (city) {
            this.$emit("show-eligibility-spoc-summary", city)
        }
    }

}
</script>
<style scoped>
 .count{
        color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
   }
</style>