<template >
    <div>
        <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white; ">
                    {{ netenquiry_city_wise.city }}
                </h6>
            </div>
            <div >
                <div v-on:click="getCityNetEnquiry(netenquiry_city_wise.city)" class="count">{{ netenquiry_city_wise.enquiry_count }}</div>
            </div>
        </vs-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            course: null,
        }
    },
    props: ["netenquiry_city_wise"],
    mounted() {
        console.log("net_enquiry", this.netenquiry_city_wise);
    },
    methods: {
        getCityNetEnquiry(city) {
            this.$emit('show-eligibility-spoc-summary', city)
        }
    }
}
</script>
<style scoped>
.count {
    color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
}
</style>