<template>
  <div>
    <vs-popup
      fullscreen
      :title="`Overall Leads City Wise |  ${this.date_props.level} : ${this.date_props.value} `"
      :active.sync="overall_leads_summary_popup"
      @close="closePopup"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-offset="0"
          vs-type="flex"
          vs-w="2"
          v-for="(overallleads_city_wise, index) in overallleads_city_wises"
          :key="index"
        >
          <overall-leads-city-wise
            :overallleads_city_wise="overallleads_city_wise"
            @show-leads-spoc-summary="getLeadsSpocSummary"
          ></overall-leads-city-wise
          ><br />
        </vs-col>
      </vs-row>
      <overall-leads-spoc-summary
        :spocs="spocs"
        :overallLeadsTableProp="overallLeadsTableProp"
        v-if="spocs.length > 0"
      ></overall-leads-spoc-summary>
    </vs-popup>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import OverallLeadsCityWise from "./OverallLeadsCityWise.vue";
import overallLeadsSpocSummary from "./overallLeadsSpocSummary.vue";
export default {
  props: [
    "overall_leads_summary_popup",
    "overallleads_city_wises",
    "date_props",
  ],
  components: {
    overallLeadsSpocSummary,
    OverallLeadsCityWise,
  },
  data() {
    return {
      overallLeadsTableProp: [],
      spocs: [],
      overal_leads_total: [],
    };
  },
  methods: {
    getLeadsSpocSummary(city, level) {
      this.overal_leads_total = []
      this.$vs.loading();
      
      let obj = {
        city: city,
        level: level,
        spoc: this.date_props.spoc,
        gm: this.date_props.gm,
      };
      ApiServices.getLeadsSpocSummary(obj)
        .then((response) => {
          console.log("spoc Summary", response.data);
          this.spocs = response.data;
          let todays = 0
          let delays = 0
          let untapped = 0
          let waiting = 0
          for (let i = 0; i < this.spocs.length; i++) {
            todays += eval(this.spocs[i].todays)
            delays += eval(this.spocs[i].delays)
            untapped += eval(this.spocs[i].untapped)
            waiting += eval(this.spocs[i].waiting)
          }
          this.overal_leads_total = {"total": "Total","delays":delays,"todays":todays,"untapped":untapped,"waiting":waiting}
          this.overallLeadsTableProp = {
            city: city,
            level: level,
            total: this.overal_leads_total
          };
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    closePopup() {
      this.$emit("close-overall_leads-popup");
      this.spocs = [];
    },
  },
};
</script>