<template>
  <div>
    <vs-table stripe max-items="10" pagination :data="spocs">
      <template slot="header">
        <div style="display: flex">
          <h3 style="margin-bottom: 10px">
            Spoc Summary - {{netEnquiryTableProp.city}}
            <!-- Spoc Summary - {{ summary_details.course }} |  {{ summary_details.city }} | {{start_date}} - {{end_date}} -->
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Name </vs-th>
        <vs-th> Reporting Manager </vs-th>
        <vs-th> {{ capitalize(keyy) }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>

          <vs-td :data="data[indextr].reporting_manager">
            {{ data[indextr].reporting_manager }}
          </vs-td>

          <vs-td :data="data[indextr][keyy]">
            {{ data[indextr][keyy] }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ["spocs","netEnquiryTableProp","keyy"],
  mounted(){
    console.log("keyy", this.keyy)
  },
  methods: {
    capitalize(val){
            const str = val.replace (/_/g, " ");
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2+ ' %'
        }
  },
};
</script>