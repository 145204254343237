<template >
    <div>
        <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white;">
                    {{ visits_city_wise.city }}
                </h6>
            </div>
            <div v-on:click="getVisitSpocSummary(visits_city_wise.city)" class="count">{{ visits_city_wise.visit_count }} </div>
        </vs-card>
    </div>
</template>
<script>
export default {
    props: ["visits_city_wise"],
    mounted() {
    },
    methods: {
        getVisitSpocSummary: function (city) {
            this.$emit("show-visit-spoc-summary", city)
        }
    }

}
</script>
<style scoped>
 .count{
        color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
   }
</style>