<template>
  <div>
    <vs-popup
      fullscreen
      :title="`Vists City Summary | ${this.date_props.startDate} - ${this.date_props.endDate}`"
      :active.sync="visits_city_summary_popup"
      @close="closePopup"
    >
      <vs-row  vs-w="12">
            <vs-col
              vs-offset="0"
              vs-type="flex"
              vs-w="2"
              v-for="(visits_city_wise, index) in visits_city_wises"
              :key="index"
            >
              <visit-calls :visits_city_wise="visits_city_wise" @show-visit-spoc-summary="getVisitSpocSummary"></visit-calls>
            </vs-col>
          </vs-row>
      <visit-spoc-summary
        :spocs="spocs"
        :visitSpocTableProp="visitSpocTableProp"
        v-if="spocs.length > 0" 
      ></visit-spoc-summary>
    </vs-popup>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import VisitCalls from './VisitCalls.vue';
import VisitSpocSummary from './VisitSpocSummary.vue';

export default {
  props: ["visits_city_summary_popup", "visits_city_wises","date_props","team"],
  components: {
    VisitSpocSummary,
    VisitCalls
  },
  data() {
    return {
      visitSpocTableProp: [],
      course: "",
      spoc: "",
      gm: "",
      spocs: [],
    };
  },
  methods: {
     getVisitSpocSummary(city) {
            this.$vs.loading();
            this.visitSpocTableProp = {"startDate":this.date_props.startDate,"endDate":this.date_props.endDate,"city":city}
            let visitSpoc = {
                startDate: this.date_props.startDate,
                endDate: this.date_props.endDate,
                city: city,
                course: this.course,
                spoc: this.spoc,
                gm: this.gm,
                team:this.team
            };
            // let url = `http://milesapi.bulbot.com/api/performance-dashboard/visits-spoc-summary/?city=${this.summary_details.city}&end-date=${this.end_date}&start-date=${this.start_date}`;
            // let url = `${constants.MILES_DASHBOARDS}getVisitsSpocSummary?start_date=${this.start_date}&end_date=${this.end_date}&city=${this.summary_details.city}&spoc=${this.summary_details.spoc}&gm=${this.summary_details.gm}`;

            // axios
            //     .get(url, {
            //         headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
            //     })
                ApiServices.getVisitSpocSummary(visitSpoc)
                .then((response) => {
                    console.log("spoc Summary", response.data)
                    this.spocs = response.data
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
        },
        closePopup(){
       this.$emit("close-visit-count-popup")
       this.spocs= []
    }
  },
};
</script>