<template>
  <div>
      <vs-popup
        fullscreen
        :title="`Eligibility City Wise | ${this.date_props.startDate} - ${this.date_props.endDate}`"
        :active.sync="eligibility_city_summary_popup"
        @close="closePopup"
      >
        <eligibility-city-wise
          :eligibility_city_wises="eligibility_city_wises"
          @show-eligibility-spoc-summary="getEligibilitySpocSummary"
        ></eligibility-city-wise>
        <eligibility-spoc-summary
          :spocs="spocs"
          :eligibilitySpocTableProp="eligibilitySpocTableProp"
          v-if="spocs.length > 0"
        ></eligibility-spoc-summary>
      </vs-popup>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import EligibilitySpocSummary from "./EligibilitySpocSummary.vue";
import EligibilityCityWise from "../../components/CmDashboard/EligibilityCityWise.vue";

export default {
  props: [
    "eligibility_city_summary_popup",
    "eligibility_city_wises",
    "date_props",
  ],
  components: {
    EligibilitySpocSummary,
    EligibilityCityWise,
  },
  data() {
    return {
      eligibilitySpocTableProp: [],
      course: "",
      spocs: [],
    };
  },
  methods: {
    getEligibilitySpocSummary(city) {
      this.$vs.loading();
      this.eligibilitySpocTableProp = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
      };
      let eligibilitySpoc = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
        course: this.course,
        spoc: this.date_props.spoc,
        gm: this.date_props.gm,
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/eligibility-spoc-summary/?city=${this.summary_details.city}&end-date=${this.end_date}&start-date=${this.start_date}`;
      // let url = `${constants.MILES_DASHBOARDS}getEligibilitySpocSummary?start_date=${this.start_date}&end_date=${this.end_date}&city=${this.summary_details.city}&spoc=${this.summary_details.spoc}&gm=${this.summary_details.gm}`;

      // axios
      //     .get(url, {
      //         headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //     })
      ApiServices.getEligibilitySpocSummary(eligibilitySpoc)
        .then((response) => {
          console.log("spoc Summary", response.data);
          this.spocs = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    closePopup() {
      this.$emit("close-eligibility-popup");
      this.spocs = []
    },
  },
};
</script>