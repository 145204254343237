

<template >
  <div>
    <vs-card class="card-shadow">
      <p class="card-header">
        {{ key_metrics_course[key_metric.course] }}
      </p>
      <div v-if="key_metric.count == 0" class="not-found">NO Data Found</div>
      <div v-else class="count">
        <div v-on:click="getCityEnrollment">{{ key_metric.count }}</div>
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      course: null,
      key_metrics_course: {
        "Total enrollments": "Total Enrollments ",
      },
    };
  },
  props: ["key_metric"],
  mounted() {
    // console.log("key_metric", this.key_metric);
  },
  methods: {
    getCityEnrollment() {
        this.$emit("total-with-city-enrollment", this.course); 
    },
  },
};
</script>
<style scoped>
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px auto;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    color: #bebcbc;
}
.count {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 4%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  font-size: 30px;
}
.card-header {
   display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #36a7fc;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.card-shadow {
  width: 160px;
  height: 120px;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>    