<template>
    <div>
      <vs-table stripe max-items="10" pagination :data="spocs">
        <template slot="header">
          <div style="display: flex">
            <h3 style="margin-bottom: 10px">
              Roadmap Spoc Summary - {{ city }}
            </h3>
          </div>
        </template>
        <template slot="thead">
          <vs-th> Name </vs-th>
          <vs-th> Reporting Manager </vs-th>
          <vs-th> City </vs-th>
          
          <vs-th> Webinar Attended </vs-th>
          <vs-th> Attendance Percentage </vs-th>
          <vs-th> Webinar Enrolled </vs-th>
          <vs-th> Enrolled Percentage </vs-th>
          <vs-th> Webinar Registrations </vs-th>
        </template>
  
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].spoc_name">
              {{ data[indextr].spoc_name }}
            </vs-td>
  
            <vs-td :data="data[indextr].reporting_manager">
              {{ data[indextr].reporting_manager }}
            </vs-td>
  
            <vs-td :data="data[indextr].city">
              {{ data[indextr].city }}
            </vs-td>
  
           
            <vs-td :data="data[indextr].webinar_attended">
              {{ data[indextr].webinar_attended }}
            </vs-td>
            <vs-td :data="data[indextr].attendance_percentage">
              {{ data[indextr].attendance_percentage }}
            </vs-td>
            <vs-td :data="data[indextr].webinar_enrolled">
              {{ data[indextr].webinar_enrolled }}
            </vs-td>
            <vs-td :data="data[indextr].enrolled_percentage">
              {{ data[indextr].enrolled_percentage }}
            </vs-td>
            <vs-td :data="data[indextr].webinar_registrations">
              {{ data[indextr].webinar_registrations }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </template>
  
  <script>
  export default {
    props: ["spocs", "city"],
  };
  </script>