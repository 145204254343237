<template>
  <div>
    <vs-table stripe max-items="10" pagination :data="spocs">
      <template slot="header">
        <div style="display: flex">
          <h3 style="margin-bottom: 10px">
            Spoc Summary - {{ overallLeadsTableProp.city }}
            <!-- Spoc Summary - {{ summary_details.course }} |  {{ summary_details.city }} | {{start_date}} - {{end_date}} -->
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Name </vs-th>
        <vs-th> Reporting Manager </vs-th>
        <vs-th> Todays </vs-th>
        <vs-th> Delays </vs-th>
        <vs-th> Untapped </vs-th>
        <vs-th> Waiting </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>

          <vs-td :data="data[indextr].reporting_manager">
            {{ data[indextr].reporting_manager }}
          </vs-td>

          <vs-td :data="data[indextr].todays">
            {{ data[indextr].todays }}
          </vs-td>

          <vs-td :data="data[indextr].delays">
            {{ data[indextr].delays }}
          </vs-td>
          <vs-td :data="data[indextr].untapped">
            {{ data[indextr].untapped }}
          </vs-td>
          <vs-td :data="data[indextr].waiting">
            {{ data[indextr].waiting }}
          </vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td></vs-td>
          <vs-td>{{ overallLeadsTableProp.total.total }}</vs-td>
          <vs-td>{{ overallLeadsTableProp.total.todays }}</vs-td>
          <vs-td>{{ overallLeadsTableProp.total.delays }}</vs-td>
          <vs-td>{{ overallLeadsTableProp.total.untapped }}</vs-td>
          <vs-td>{{ overallLeadsTableProp.total.waiting }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ["spocs", "overallLeadsTableProp"],
};
</script>