<template >
    <div>
        <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white;">
                    {{ city_wises_enrollment.city }}
                </h6>
            </div>
            <div v-on:click="getSpocDetail(city_wises_enrollment.city)" class="count">{{ city_wises_enrollment.count }}
            </div>
        </vs-card>
    </div>
</template>
<script>
export default {
    props: ["city_wises_enrollment"],
    data(){
        return {
            // course : null
        }
    },
    methods: {
        getSpocDetail(city) {
            this.$emit("show-city-enrollment", city)
        }
    }
}
</script>
<style scoped>
.count {
    color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
}
</style>