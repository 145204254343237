<template>
  <div>
    <vs-popup
      fullscreen
      :title="`Net Enquiry City Wise | ${this.date_props.startDate} - ${this.date_props.endDate} : ${this.date_props.source} `"
      :active.sync="net_enquiry_summary_popup"
      @close="closePopup"
    >
      <vs-row  vs-w="12">
        <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-w="2"
            v-for="(netenquiry_city_wise, index) in netenquiry_city_wises"
            :key="index"
        >
            <net-enquiry-city-wise :netenquiry_city_wise="netenquiry_city_wise" @show-eligibility-spoc-summary="getNetEnquirySpocSummary" ></net-enquiry-city-wise>
        </vs-col>
      </vs-row>
      <net-enquiry-spoc-summary
        :spocs="spocs"
        :keyy="`${date_props.key}`"
        :netEnquiryTableProp="netEnquiryTableProp"
        v-if="spocs.length > 0"
      ></net-enquiry-spoc-summary>
    </vs-popup>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import NetEnquiryCityWise from './NetEnquiryCityWise.vue';
import NetEnquirySpocSummary from './NetEnquirySpocSummary.vue';
export default {
  props: [
    "net_enquiry_summary_popup",
    "netenquiry_city_wises",
    "date_props",
  ],
  components: {
    NetEnquirySpocSummary,
    NetEnquiryCityWise,
  },
  data() {
    return {
      netEnquiryTableProp: [],
      source: "",
      spocs: [],
    };
  },
  methods: {
    getNetEnquirySpocSummary(city) {
      this.$vs.loading();
      this.netEnquiryTableProp = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
      };
      let obj = {
        startDate: this.date_props.startDate,
        endDate: this.date_props.endDate,
        city: city,
        source: this.date_props.source,
        spoc: this.date_props.spoc,
        gm: this.date_props.gm
      };
      ApiServices.getNetEnquirySpocSummary(obj)
        .then((response) => {
          console.log("spoc Summary", response.data);
          this.spocs = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    closePopup() {
      this.$emit("close-net-enquiry-popup");
      this.spocs = []
    },
  },
};
</script>