<template >
    <div>
      <!-- <div v-if="(roadmap_city_wises.length < 1)">No Data Available</div> -->
      <div v-if="roadmap_city_wises.length > 0">
        <!-- <vs-card style="width: 150px; height:100px; background: #948bf4;">
              <div slot="header" style="text-align: center;">
                  <h6 style="color: white;">
                      {{ referrals_city_wise.city }}
                  </h6>
              </div>
              <div v-on:click="getReferralsSpocSummary(referrals_city_wise.city)" class="count">{{ referrals_city_wise.overall_referral_enrollments }} </div>
          </vs-card> -->
        <vs-table max-items="5" pagination stripe :data="roadmap_city_wises">
          <template slot="thead">
            <vs-th> City </vs-th>
            <vs-th> Webinar Registrations </vs-th>
            <vs-th> Webinar Attended </vs-th>
            <vs-th> Attendance % </vs-th>
            <vs-th> Webinar Enrolled </vs-th>
            <vs-th>Enrolled % </vs-th>
          </template>
  
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].city">
                {{  data[indextr].city === null ? "Other": data[indextr].city }}
              </vs-td>
  
              <vs-td :data="data[indextr].webinar_registrations">
                <div
                  style="color: #948bf4"
                  @click="getRoadmapSpocSummary(data[indextr].city)"
                >
                  {{ data[indextr].webinar_registrations === null ? "0": data[indextr].webinar_registrations}}
                </div>
              </vs-td>
              <vs-td :data="data[indextr].webinar_attended">
                <div>{{ data[indextr].webinar_attended === null ? "0": data[indextr].webinar_attended}}</div>
              </vs-td>
              <vs-td :data="data[indextr].attendance_percentage">
                <div>
                  {{   data[indextr].attendance_percentage === null ? "0": data[indextr].attendance_percentage}}
                </div>
              </vs-td>
              <vs-td :data="data[indextr].webinar_enrolled">
                <div>
                  {{  data[indextr].webinar_enrolled === null ? "0": data[indextr].webinar_enrolled}}
                </div>
              </vs-td>
              <vs-td :data="data[indextr].enrolled_percentage">
                <div>
                  {{  data[indextr].enrolled_percentage === null ? "0": data[indextr].enrolled_percentage}}
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: ["roadmap_city_wises"],
    mounted() {},
    methods: {
      getRoadmapSpocSummary: function (city) {
        this.$emit("show-roadmap-spoc-summary", city);
        console.log("spoc Summary",city)
      },
    },
  };
  </script>
  <style scoped>
  .count {
    color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
  }
  </style>