<template >
  <div>
    <vs-card class="card-shadow" style="width: 160px; height: 124px">
      <h6 class="card-header">
        {{ courseHeader(course_wise.course) }}
      </h6>
      <div v-on:click="getCityEnrollment(course_wise)" class="count"  v-if="course_wise.count != 0">
        {{ course_wise.count }}
      </div>
      <div v-else class="not-found">
        <b>No Data Found</b>
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  props: ["course_wise"],
  methods: {
    getCityEnrollment: function (val) {
      if (val.count>0) {
        this.$emit("show-city-enrollment", val.course);
      }
    },
    courseHeader(val) {
      if (val == "aicpa_miles_enrolled") {
        return "AICPA Miles Enrolled";
      } else if (val == "aicpa_non_miles_enrolled") {
        return "AICPA Non Miles Enrolled";
      } else {
        return val;
      }
    },
  },
};
</script>
<style scoped>
.count {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  font-size: 30px;
}

.card-shadow {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #669eff;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
    .not-found{
    font-size: 11px;
}
}
</style>