<template >

  <vs-card class="card-shadow" style="width: 160px; height: 124px; border-radius: 15px;">

    <h6 class="card-header">
      {{ netinquiryHeader(net_enquiry_key) }}
    </h6>

    <div class="count" v-if="net_enquiry != 0">
      <div v-on:click="getCityNetEnquiry(source,net_enquiry_key,net_enquiry)"> {{ net_enquiry }}
        <span v-if="net_enquiry_key =='new_netenquiry_conversion' || net_enquiry_key =='netenquiry_enrollment_conversion'">%</span>
      </div>
    </div>
    <div v-else class="not-found">
      <b>No Data Found</b>
    </div>
  </vs-card>
</template>
<script>
export default {
  data() {
    return {
      course: null,
    };
  },
  props: ["net_enquiry", "net_enquiry_key", "source"],
  methods: {
    getCityNetEnquiry(source, key, count) {
      if (count !== 0) {
        this.$emit("total-with-city-netenquiry", source, key);
      }
    },
    netinquiryHeader(val) {
      console.log("val",val);
      if(val == "total_enrollments"){
        return "Enrollments"
      }
      if (val == "overall_netenquiry_enrollments") {
        return "Overall NE Enrollment"
      } if (val == "new_netenquiries") {
        return "New Net Enquiries"
      } if (val == "new_netenquiry_conversion") {
        return "Conversion % (New NE)"
      } if (val == "new_enquiry_enrollments") {
        return "New Enquiry Enrollment"
      } if (val == "netenquiry_enrollment_conversion") {
        return "Enrollment % from NE"
      } else {
        this.capitalize(val)
      }
    },
    capitalize(val) {
      const str1 = val.replace(/_/g, " ");
      // const str1 = str.replace(/netenquiry/g, "NE");
      const arr = str1.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2
    }
  },
};
</script>
<style scoped>
.count {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5%;
  color: #1E1E1E;
  padding: 10px;
  font-weight: 600;
  font-size: 30px;
}

.card-shadow {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #1CE6CB;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
    .not-found{
    font-size: 11px;
}
}
</style>