<template >
  <div>
    <vs-table stripe :data="overall_leads">
      <template slot="thead">
        <vs-th>
          Levels
        </vs-th>
        <vs-th>
          Todays
        </vs-th>
        <vs-th>
          Delays
        </vs-th>
        <vs-th>
          Untapped
        </vs-th>
        <vs-th>
          Waiting
        </vs-th>
        <vs-th>
          Total
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].classified_level">
            {{ data[indextr].classified_level.toUpperCase() }}
          </vs-td>

          <vs-td :data="data[indextr].todays">
            <div> {{ data[indextr].todays}}</div>
          </vs-td>
          <vs-td :data="data[indextr].delays">
            <div> {{ data[indextr].delays}}</div>
          </vs-td>
          <vs-td :data="data[indextr].untapped">
            <div>{{ data[indextr].untapped }} </div>
          </vs-td>
          <vs-td :data="data[indextr].waiting">
            <div>{{ data[indextr].waiting }} </div>
          </vs-td>
          <vs-td :data="data[indextr].total">
            <div @click="getDataByLevel(data[indextr].total,data[indextr].classified_level)" style="color:#948bf4">{{ data[indextr].total }} </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
export default {
  props: ["overall_leads"],
  data() {
    return {
    };
  },
  methods: {
    getDataByLevel(count,level) {
      let source ={ "level":level,"count":count}
      this.$emit("total-count-overallleads", source);
    },
  },
};
</script>
<style scoped>
.count {
  color: black;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  background-color: white;
  margin: -8%;
}
</style>