<template >
  <div>
    <vs-card style="width: 150px; height: 100px; background: #948bf4">
      <div slot="header" style="text-align: center">
        <h6 style="color: white">
          {{ overallleads_city_wise.city }}
        </h6>
      </div>
      <div>
        <div
          v-on:click="
            getDataByCity(
              overallleads_city_wise.city,
              overallleads_city_wise.classified_level
            )
          "
          class="count"
        >
          {{ overallleads_city_wise.total }}
        </div>
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      course: null,
    };
  },
  props: ["overallleads_city_wise"],
  methods: {
    getDataByCity(city, level) {
      this.$emit("show-leads-spoc-summary", city, level);
    },
  },
};
</script>
<style scoped>
.count {
  color: black;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  background-color: white;
  margin: -8%;
}
</style>