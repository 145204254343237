<template >
  <div>
    <vs-card
      style="width: 160px; height: 124px; border-radius: 15px;"
      :class="getShadow(road_map_key)"
    >
      <h6 class="card-header">
        {{ roadmap_header[road_map_key] }}
      </h6>
      <div v-on:click="getRoadmapCitySummary(road_map_key,road_map_value)" class="count" v-if="road_map_value != 0">
        {{ road_map_value }}
        <span
          v-if="
            road_map_key == 'attendance_percentage' ||
            road_map_key == 'enrolled_percentage'
          "
          >%</span
        >
      </div>
      <div v-else class="not-found">
        <b>No Data Found</b>
      </div>
      <!-- v-on:click="getReferralsCitySummary(road_map_key)" -->
    </vs-card>
  </div>
</template>
<script>
export default {
  props: ["road_map_key", "road_map_value"],
  data() {
    return {
      roadmap_header: {
        webinar_registrations: "Webinar Registrations",
        webinar_attended: "Webinar Attended",
        attendance_percentage: "Attendance %",
        webinar_enrolled: "Webinar Enrolled",
        enrolled_percentage: "Enrolled %",
      },
    };
  },
  methods: {
    getRoadmapCitySummary: function (val,count) {
      // console.log("getRoadmapCitySummary",val)
      if (count > 0) {
        this.$emit("show-road-map-type", val);
      }
    },
    getShadow(referral_key) {
      if (referral_key == "webinar_registrations") return "boxShadow";
    },
  },
};
</script>
<style scoped>
.count {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  font-size: 30px;
}

.boxShadow {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #eb76cd;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
    .not-found{
    font-size: 11px;
}
}
</style>