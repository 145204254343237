<template>
  <div>
    <vs-table stripe max-items="10" pagination :data="spocs">
      <template slot="header">
        <div style="display: flex">
          <h3 style="margin-bottom: 10px">
            Visits Spoc Summary - {{ visitSpocTableProp.city }}
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Name </vs-th>
        <vs-th> Reporting Manager </vs-th>
        <vs-th> City </vs-th>
        <vs-th> Visit Count </vs-th>
        <vs-th> Visit Utilization </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>

          <vs-td :data="data[indextr].reporting_manager">
            {{ data[indextr].reporting_manager }}
          </vs-td>

          <vs-td :data="data[indextr].city">
            {{ data[indextr].city }}
          </vs-td>

          <vs-td :data="data[indextr].visit_count">
            {{ data[indextr].visit_count }}
          </vs-td>
          <vs-td :data="data[indextr].visit_utilization">
            {{ data[indextr].visit_utilization }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ["visitSpocTableProp", "spocs"],
};
</script>