<template>
    <div>
        <vs-table stripe max-items="10" pagination :data="spocs">
            <template slot="header">
                <div style="display: flex;">
                    <h3 style="margin-bottom: 10px;">
                        Calls Spoc Summary - {{ callSpocTableProp.city }}
                    </h3>
                </div>
            </template>
            <template slot="thead">
                <vs-th>
                    Name
                </vs-th>
                <vs-th>
                    Reporting Manager
                </vs-th>
                <vs-th>
                    City
                </vs-th>
                <vs-th>
                    Connected Calls
                </vs-th>
                <vs-th>
                    Call Utilization
                </vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].spoc_name">
                        {{ data[indextr].spoc_name }}
                    </vs-td>

                    <vs-td :data="data[indextr].reporting_manager">
                        {{ data[indextr].reporting_manager }}
                    </vs-td>

                    <vs-td :data="data[indextr].city">
                        {{ data[indextr].city }}
                    </vs-td>

                    <vs-td :data="data[indextr].connected_calls">
                        {{ data[indextr].connected_calls }}
                    </vs-td>
                    <vs-td :data="data[indextr].call_utilization">
                        {{ data[indextr].call_utilization }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
export default {
    props:["spocs","callSpocTableProp"],
}
</script>