<template>
  <div >
    <vs-popup fullscreen :title="`Referral City Wise | ${this.date_props.startDate}- ${this.date_props.endDate}`" :active.sync="referral_city_summary_popup" @close="closePopup">
        <referrals-city-wise 
            :referrals_city_wises="referrals_city_wises"
            @show-referrals-spoc-summary="getReferralsSpocSummary">
        </referrals-city-wise>
      <referrals-spoc-summary :spocs="spocs" :city="city" v-if="spocs.length > 0"></referrals-spoc-summary>
    </vs-popup>
  </div>
</template>

<script>
import ApiServices from "../../ApiServices";
import ReferralsCityWise from './ReferralsCityWise.vue';
import ReferralsSpocSummary from './ReferralsSpocSummary.vue';


export default {
    props:["referral_city_summary_popup","referrals_city_wises","date_props"],
    components:{
      ReferralsCityWise,
      ReferralsSpocSummary
    },
  data(){
    return {
      course : "",
      spocs: [],
      city: []
    }
  },
  methods:{
     getReferralsSpocSummary(city) {
            this.$vs.loading();
            this.city = {"city" :city}
            let referralSpoc = {
                startDate: this.date_props.startDate,
                endDate: this.date_props.endDate,
                city: city,
                course: this.course,
                spoc: this.date_props.spoc,
                gm: this.date_props.gm
            };
            // let url = `http://milesapi.bulbot.com/api/performance-dashboard/referral-spoc-summary/?city=${this.summary_details.city}&end-date=${this.end_date}&start-date=${this.start_date}`;
            // let url = `${constants.MILES_DASHBOARDS}getReferralSpocSummary?start_date=${this.start_date}&end_date=${this.end_date}&city=${this.summary_details.city}&spoc=${this.summary_details.spoc}&gm=${this.summary_details.gm}`;

            // axios
            //     .get(url, {
            //         headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
            //     })
                ApiServices.getReferralsSpocSummary(referralSpoc)
                .then((response) => {
                    this.spocs= []
                    console.log("spoc Summary", response.data)
                    this.spocs = response.data
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
    },
    closePopup() {
      this.$emit("close-referral-city-popup");
      this.spocs= []
    },
  }
}
</script>