<template>
    <div>
        <vs-card>
                <h3 class="card-main-header">ALL INDIA RANK SUMMARY <span v-if="spoc !==''">- {{spoc}}</span></h3>
           
            <vs-row style="margin-top: 2%">
                <vs-col vs-justify="space-around" vs-align="center" vs-type="flex">
                    <vs-card class="card-shadow"  v-for="(value, key, index) in all_india_rank_summary" :key="index"  v-if="key != 'spoc_name'">
                        <h6 class="card-header">
                            {{ headers[key]}}
                        </h6>
                        <div class="count">
                            <div>{{ value }}</div>
                        </div>
                    </vs-card>
                </vs-col>
            </vs-row>
        </vs-card>
    </div>
</template>

<script>
export default {
    props: ["all_india_rank_summary", "spoc"],
    data() {
        return {
            headers: {
                'enrollment_air': "Enrollment AIR",
                'visit_air': "Visit AIR",
                'connected_calls_air': "Connected Calls AIR",
                'call_utilization_air': "Call Utilization AIR",
                'visit_utilization_air': "Visit Utilization AIR",
            },
        }
    },
    mounted() {
        console.log("spoc,,,,,,",this.spoc)
        console.log("all_india_rank_summary---", this.all_india_rank_summary)
    }
}
</script>

<style scoped>
.count {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  font-size: 30px;
}
.card-header {
     display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  background: #36a7fc;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}
.card-main-header {
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
.card-shadow {
  width: 160px;
  height: 124px;
 
}
</style>