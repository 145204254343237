<template >
  <div>
    <vs-card
      style="width: 160px; height: 124px; border-radius: 15px;"
      :class="getShadow(eligibility_key)"
    >
      <h6 class="card-header">
        {{ eligibilityHeader(eligibility_key) }}
      </h6>
      <div
        v-on:click="getEligibilityCitySummary(eligibility_key,eligibility_value)"
        class="count" v-if="eligibility_value != 0"
      >
        {{ eligibility_value }}
        <span
          v-if="
            eligibility_key == 'eligibility_checks_among_enrolled' ||
            eligibility_key == 'new_eligibility_conversion'
          "
          >%</span
        >
      </div>
      <div v-else class="not-found">
        <b>No Data Found</b>
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  props: ["eligibility_key", "eligibility_value"],
  data() {
    return {
      eligibility_header: {
        overall_eligibility_enrollments: "Overall Eligibility Enrollments",
        eligibility_checks_among_enrolled:
          "Eligibility Checks Among Enrolled(%)",
        new_eligibility_checks: "New Eligibility Checks",
        new_eligibility_checks_enrolled: "New Eligibility Checks Enrolled",
        new_eligibility_conversion: "New Eligibility Conversion(%)",
      },
    };
  },
  methods: {
    getEligibilityCitySummary: function (val,count) {
      if (count >0) {
        this.$emit("show-eligibility-city-summary", val);
      }
    },
    getShadow(eligibility_key) {
      if (eligibility_key == "overall_eligibility_enrollments")
        return "boxShadow";
    },
    getPercentage() {
      return "%";
    },
    eligibilityHeader(val) {
      console.log("val", val);
      // return val
      if (val == "overall_eligibility_enrollments") {
        return "Overall Eligibility Enrollment";
      }
      if (val == "eligibility_checks_among_enrolled") {
        return "Enrollment % from Eligibility Checks";
      }
      if (val == "new_eligibility_checks_enrolled") {
        return "New Eligibility Checks Enrollment";
      }
      if (val == "new_eligibility_conversion") {
        return "Conversion % (New Eligibilities)";
      }
      if (val == "new_eligibility_checks") {
        return "New Eligibility Checks";
      }
    },
  },
};
</script>
<style scoped>
.count {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  color: black;
  text-align: center;
  font-size: 30px;
}
.boxShadow {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #9666f6;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  margin: -8%;
}
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
    .not-found{
    font-size: 11px;
}
}
</style>