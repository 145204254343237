<template>
    <div>
        <vs-card v-if="show_chart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </vs-card>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    props: ["overall_trends"],
    components: {
        apexchart: VueApexCharts,
    },
    mounted() {
        console.log("overall_trends", this.overall_trends)
        this.getChartData();

    },
    watch: {
        overall_trends: function () {
            this.series = [];
            this.chartOptions.xaxis.categories = []
            this.getChartData();
        }
    },
    data() {
        return {
            show_chart: false,
            series: [],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Trends Summary',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    categories: [],

                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                            }
                        },
                        title: {
                            text: "Enrollments",
                            style: {
                                color: '#008FFB',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                    {
                        seriesName: 'Visits Count',
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            style: {
                                colors: '#00E396',
                            }
                        },
                        title: {
                            text: "Visits",
                            style: {
                                color: '#00E396',
                            }
                        },
                    },
                    {
                        seriesName: 'Connected Call Count',
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#FEB019'
                        },
                        labels: {
                            style: {
                                colors: '#FEB019',
                            }
                        },
                        title: {
                            text: "Connected Calls",
                            style: {
                                color: '#FEB019',
                            }
                        },
                    },
                ],
                noData: {
                    text: "Data not available",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "#808080",
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                }
            },
            enrollment_count: [],
            visit_count: [],
            connected_call_count: [],
            call_utilization: [],
            visit_utilization: [],

        }
    },
    methods: {
        getChartData() {
            // console.log("categories", this.chartOptions.xaxis.categories)
            if (this.overall_trends.length > 0) {
                for (let i = 0; i < this.overall_trends.length; i++) {
                    this.chartOptions.xaxis.categories.push(this.overall_trends[i].month)
                    this.enrollment_count.push(this.overall_trends[i].enrollment_count)
                    this.visit_count.push(this.overall_trends[i].visit_count)
                    this.connected_call_count.push(this.overall_trends[i].connected_call_count)
                    // this.call_utilization.push(this.overall_trends[i].call_utilization)
                    // this.visit_utilization.push(this.overall_trends[i].visit_utilization)
                }
                this.series.push(
                    {
                        name: "Enrollment Count",
                        data: this.enrollment_count
                    },
                    {
                        name: "Visit Count",
                        data: this.visit_count
                    },
                    {
                        name: "Connected Call Count",
                        data: this.connected_call_count
                    },
                    // {
                    //     name: "Call Utilization",
                    //     data: this.call_utilization
                    // },
                    // {
                    //     name: "Visit Utilization",
                    //     data: this.visit_utilization
                    // },
                )
                // console.log("categories--", this.chartOptions.xaxis.categories)
                // console.log("series--", this.series)
            }
            
            this.show_chart = true



        }
    }
}
</script>
<style>
</style>