<template>
    <div>
        <vs-card>
            <div slot="header">
                <h3>Leads Summary</h3>
            </div>
            <div>
                <vs-row vs-justify="center" vs-type="flex" vs-w="12" v-if="is_admin">
                    <vs-col vs-w="7"></vs-col>
                    <vs-col vs-w="2">
                        <v-select searchable label="City" placeholder="City" :options="cities" v-model="selected_city">
                        </v-select>

                    </vs-col>
                    <vs-col vs-w="2">
                        <v-select searchable label="City Classification" placeholder="City Classification"
                            :options="city_classification" v-model="selected_city_classification">
                        </v-select>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                        <vs-button color="dark" icon="search" @click="getCityData"></vs-button>
                    </vs-col>
                </vs-row>

                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"
                        style="font-size: 19px">
                        <b>Call Logs</b>
                    </vs-col>
                    <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"
                        style="font-size: 19px">
                        <b>Work Logs</b>
                    </vs-col>
                </vs-row>
                <div style="
          width: 100%;
          max-height: 500px;
          height: auto;
          overflow: auto;
          margin-left: 10px;
        ">
                    <table width="700px">
                        <thead>
                            <tr>
                                <th style="min-width: 75px; width: 75px" class="col-id-no fixed-header">
                                    Spoc Name
                                </th>
                                <th style="min-width: 100px; width: 100px" class="col-first-name fixed-header">
                                    Reporting To
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    HF/NHF
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    City Tier
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Missed Calls
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Untracked
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    To Update
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Call Logs Today
                                </th>

                                <th style="min-width: 100px; width: 100px">
                                    IVR All Calls
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    IVR Missed Calls
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Untapped
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Delays
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    NE Delays
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Other Delays
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Today
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Waiting
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    MHPS
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Net Enquiries
                                </th>
                                <th style="min-width: 100px; width: 100px">
                                    Flag
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tr, index) in table_data" :key="index">
                                <td class="col-id-no" scope="row">{{ tr.spoc_name }}</td>
                                <td class="col-first-name" scope="row">{{ tr.reporting_manager }}</td>
                                <td>{{ tr.user_tag }}</td>
                                <td>{{ tr.city_classification }}</td>
                                <td>
                                    <a @click="getData(tr.user_id, 'missed_calls')">
                                        {{ tr.missed_calls }}
                                    </a>
                                </td>
                                <td><a @click="getData(tr.user_id, 'untracked')">
                                        {{ tr.untracked_calls }}
                                    </a></td>
                                <td>
                                    <a @click="getData(tr.user_id, 'to_update')">
                                        {{ tr.to_update_calls }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'call_logs_today')">
                                        {{ tr.today_calls_logs }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'ivr_all_calls')">
                                        {{ tr.ivr_all_calls }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'ivr_missed_calls')">
                                        {{ tr.ivr_missed_calls }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'untapped')">
                                        {{ tr.untapped }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'delays')">
                                        {{ tr.delays }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'ne_delays')">
                                        {{ tr.ne_delays }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'other_delays')">
                                        {{ tr.other_delays }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'today')">
                                        {{ tr.today }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'waiting')">
                                        {{ tr.waiting_calls }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'mhps')">
                                        {{ tr.mhp }}
                                    </a>
                                </td>
                                <td>
                                    <a @click="
                                        getData(
                                            tr.user_id,
                                            'net_enquiries',
                                            tr.net_enquiries
                                        )
                                    ">{{ tr.net_enquiries }}</a>
                                </td>
                                <td>
                                    <a @click="getData(tr.user_id, 'flag')">{{
                                            tr.flag
                                    }}</a>
                                </td>
                            </tr>
                            <vs-tr v-show="hidetotal">
                                <vs-td class="col-id-no">
                                    {{ total.Header }}
                                </vs-td>
                                <vs-td class="col-first-name"> </vs-td>
                                <vs-td> </vs-td>
                                <vs-td>
                                    {{ total.missed_calls }}
                                </vs-td>
                                <vs-td>
                                    {{ total.untracked }}
                                </vs-td>
                                <vs-td>
                                    {{ total.to_update }}
                                </vs-td>
                                <vs-td>
                                    {{ total.call_logs_today }}
                                </vs-td>
                                <vs-td>
                                    {{ total.ivr_all_calls }}
                                </vs-td>
                                <vs-td>
                                    {{ total.ivr_missed_calls }}
                                </vs-td>
                                <vs-td>
                                    {{ total.untapped }}
                                </vs-td>
                                <vs-td>
                                    {{ total.delays }}
                                </vs-td>
                                <vs-td>
                                    {{ total.ne_delays }}
                                </vs-td>
                                <vs-td>
                                    {{ total.other_delays }}
                                </vs-td>
                                <vs-td>
                                    {{ total.today }}
                                </vs-td>
                                <vs-td>
                                    {{ total.waiting }}
                                </vs-td>
                                <vs-td>
                                    {{ total.mhps }}
                                </vs-td>
                                <vs-td>
                                    {{ total.net_enquiries }}
                                </vs-td>
                                <vs-td>
                                    {{ total.flag }}
                                </vs-td>
                            </vs-tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </vs-card>
        <SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
        <SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
        <SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
        <SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
        <SpocDBAdminIVRallCalls></SpocDBAdminIVRallCalls>
    </div>
</template>
<script>
import vSelect from "vue-select";
import SpocDashboardCallLogPopup from "../pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardAdminWorkLog from "../pagesComponents/SpocDashboardAdminWorkLog.vue";
import SpocDashboardCmNetEnquiries from "../pagesComponents/SpocDashboardCMNetenquiries.vue";
import SpocDBAdminIVRallCalls from "../pagesComponents/SpocDBAdminIVRallCalls.vue";
import eventbus from "../../components/eventbus";
import constants from "../../../constants.json";
import axios from "axios";
export default {
    props: ["leads_summary","select_city"],
    components: {
        "v-select": vSelect,
        SpocDashboardCallLogPopup,
        SpocDashboardAdminToUpdate,
        SpocDashboardAdminWorkLog,
        SpocDashboardCmNetEnquiries,
        SpocDBAdminIVRallCalls,

    },
    mounted() {
        console.log("leads_summary", this.select_city)
        this.selected_city = this.select_city
        this.selected_city_classification = "All";
        this.getCityData();
        if (localStorage.getItem('team') == 'Admin') {
            this.getCities();
            this.is_admin = true;
        } else if (localStorage.getItem('team') == 'GM') {
            this.is_admin = false;
            this.getGmData();
        } else { 
            this.is_admin = false;
            this.hidetotal = false

            this.getSpocData();
        }


    },
    watch: {
        selected_city: function (val) {
            this.city_classification = [];
            let classification = ["All"];
            for (let i = 0; i < this.leads_summary.length; i++) {
                if (
                    val == this.leads_summary[i].city &&
                    this.leads_summary[i].team == "CM"
                ) {
                    classification.push(this.leads_summary[i].city_classification);
                }
            }
            console.log("classification", classification);
            // To get distinct values from an array
            this.city_classification = [
                ...new Set(classification.map((item) => item)),
            ];
        },
    },
    data() {
        return {
            cities: [],
            selected_city: "",
            city_classification: [],
            selected_city_classification: "",
            table_data: [],
            hidetotal: false,
            total: {
                missed_calls: [],
                untracked: [],
                to_update: [],
                call_logs_today: [],
                untapped: [],
                delays: [],
                today: [],
                waiting: [],
                net_enquiries: [],
                mhps: [],
                flag: [],
            },
            is_admin: false
        }
    },
    methods: {
        getCities() {
            this.cities = [
                ...new Set(this.leads_summary.map((item) => item.city)),
            ];
        },
        getCityData() {
            this.table_data = [];
            if (this.selected_city_classification != "") {
                if (this.selected_city_classification === "All") {
                    for (let i = 0; i < this.leads_summary.length; i++) {
                        if (this.selected_city == this.leads_summary[i].city) {
                            this.table_data.push(this.leads_summary[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < this.leads_summary.length; i++) {
                        if (
                            this.selected_city == this.leads_summary[i].city &&
                            this.selected_city_classification ==
                            this.leads_summary[i].city_classification
                        ) {
                            this.table_data.push(this.leads_summary[i]);
                        }
                    }
                }
            }
            this.totalCalculate(this.table_data);
        },
        totalCalculate(Spocs) {
            this.total.Header = "TOTAL";
            this.total.missed_calls = Spocs.reduce(
                (n, { missed_calls }) => n + missed_calls,
                0
            );
            this.total.untracked = Spocs.reduce(
                (n, { untracked_calls }) => n + untracked_calls,
                0
            );
            this.total.to_update = Spocs.reduce(
                (n, { to_update_calls }) => n + to_update_calls,
                0
            );
            this.total.call_logs_today = Spocs.reduce(
                (n, { today_calls_logs }) => n + today_calls_logs,
                0
            );
            this.total.untapped = Spocs.reduce((n, { untapped }) => n + untapped, 0);
            this.total.delays = Spocs.reduce((n, { delays }) => n + delays, 0);
            this.total.today = Spocs.reduce((n, { today }) => n + today, 0);
            this.total.waiting = Spocs.reduce(
                (n, { waiting_calls }) => n + waiting_calls,
                0
            );
            this.total.mhps = Spocs.reduce((n, { mhp }) => n + mhp, 0);
            this.total.net_enquiries = Spocs.reduce(
                (n, { net_enquiries }) => n + net_enquiries,
                0
            );
            this.total.flag = Spocs.reduce((n, { flag }) => n + flag, 0);
            this.total.ivr_all_calls = Spocs.reduce((n, { ivr_all_calls }) => n + ivr_all_calls, 0);
            this.total.ivr_missed_calls = Spocs.reduce((n, { ivr_missed_calls }) => n + ivr_missed_calls, 0);
            this.total.ne_delays = Spocs.reduce((n, { ne_delays }) => n + ne_delays, 0);
            this.total.other_delays = Spocs.reduce((n, { other_delays }) => n + other_delays, 0);
            console.log("total", this.total);
            this.hidetotal = true;
        },
        getData(spoc_id, category) {
            console.log("table data in get data", this.table_data);
            if (spoc_id != null && category != "") {
                let spocName = null;
                this.table_data.forEach((spoc) => {
                    if (spoc.user_id === spoc_id) {
                        spocName = spoc.spoc_name;
                    }
                });
                let url = `${constants.ADMIN_SERVER_API}getSpocDashboardForAdminDetails`;
                let obj = {};
                if (category != "net_enquiries") {
                    obj = {
                        spoc_id: spoc_id,
                        category: category,
                    };
                }
                if (category == "net_enquiries") {
                    obj = {
                        spoc_id: spoc_id,
                        category: category,
                        selected_city: this.selected_city
                    };
                }
                axios
                    .get(url, {
                        params: obj,
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.response = response;
                        if (category === "net_enquiries") {
                            eventbus.$emit(
                                "spocDashboardAdminCM-NetEnquiries",
                                // NetEnquiriesData,
                                // spocName
                                response.data,
                                url,
                                obj,
                                category,
                                spocName
                            );
                        }
                        if (category === "missed_calls" || category === "untracked") {
                            console.log("checking response", response);
                            eventbus.$emit(
                                "spocDashboardAdmin-calllog",
                                response.data,
                                url,
                                obj,
                                category,
                                spocName
                            );
                        } else if (category === "to_update") {
                            eventbus.$emit(
                                "spocDashboardAdmin-toUpdate",
                                response.data,
                                url,
                                obj,
                                category,
                                spocName
                            );
                        } else if (
                            category === "delays" ||
                            category === "ne_delays" ||
                            category === "other_delays" ||
                            category === "today" ||
                            category === "waiting" ||
                            category === "mhps" ||
                            category === "untapped" ||
                            category === "flag"
                        ) {
                            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
                            eventbus.$emit("spocDashboardAdmin-WorkLog", [
                                response,
                                url,
                                obj,
                                spocName,
                            ]);
                        } else if (category === "ivr_all_calls" || category === "ivr_missed_calls") {
                            eventbus.$emit(
                                "spocDashboardAdmin-IVRcalls",
                                response.data.data,
                                url,
                                obj,
                                category,
                                spocName
                            );
                        }
                        // else if (category === "net_enquiries") {
                        //   this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
                        //   eventbus.$emit("spocDashboardAdminCM-NetEnquiries", [
                        //     response,
                        //     url,
                        //     obj,
                        //   ]);
                        // }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            } else {
                this.$vs.notify({
                    title: "Error",
                    color: "danger",
                });
            }
        },
        getSpocData() {
            let spoc_id = localStorage.getItem('spoc_id');
            for (let i = 0; i < this.leads_summary.length; i++) {
                if (spoc_id == this.leads_summary[i].user_id) {
                    this.table_data.push(this.leads_summary[i]);
                }
            }
        },
        getGmData() {
            let gm_id = localStorage.getItem('spoc_id');
            for (let i = 0; i < this.leads_summary.length; i++) {
                if (gm_id == this.leads_summary[i].reporting_to) {
                    this.table_data.push(this.leads_summary[i]);
                }
            }
            this.totalCalculate(this.table_data);
        }
    }
}
</script>
<style scoped>
thead th {
    top: 0;
    position: sticky;
    z-index: 20;
    background-color: white;
    color: black;
}

.col-id-no {
    left: 0;
    position: sticky;
    background-color: white;
}

.col-first-name {
    left: 80px;
    position: sticky;
    background-color: white;
}

.fixed-header {
    z-index: 50;
}

table th,
td {
    outline: 1px solid #ccc;
}
</style>