<template >
    <div>
        <vs-card style="width: 150px; height:100px; background: #948bf4;">
            <div slot="header" style="text-align: center;">
                <h6 style="color: white;">
                    {{ calls_city_wise.city }}
                </h6>
            </div>
            <div v-on:click="getCallsSpocSummary(calls_city_wise.city)" class="count">{{ calls_city_wise.connected_calls }} </div>
        </vs-card>
    </div>
</template>
<script>
export default {
    props: ["calls_city_wise"],
    mounted() {
        console.log("calls",this.calls_city_wise);
    },
    methods: {
        getCallsSpocSummary: function (city) {
            this.$emit("show-calls-spoc-summary", city)
        }
    }

}
</script>
<style scoped>
 .count{
        color: black;
    text-align: center;
    font-size: 30px;
    padding: 10px;
    background-color: white;
    margin: -8%;
   }
</style>